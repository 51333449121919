<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>

				<ej-buttons style="margin-left: auto">
					<ej-button
						@click="sortingToggle"
						:class="{ active: sortingOpened, 'mr-1': true }"
						v-if="_.get($store.state, `${module}.sorting.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'sort']"></font-awesome-icon>
					</ej-button>

					<ej-button
						@click="filteringToggle"
						:class="{ active: filteringOpened }"
						v-if="_.get($store.state, `${module}.filtering.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
					</ej-button>
				</ej-buttons>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<div class="header">
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-7">
							<div :class="{ row: true, 'mobile-hidden': filteringMobileHidden }">
								<h4 class="col-12">Filtros</h4>

								<div class="form-group col-lg-4 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="filteringField"
										:options="$store.state[module].filtering.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Critério:</label>
									<multiselect
										v-model="filteringCriteria"
										:options="$store.state[module].filtering.criterias"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Pesquisar por:</label>
									<input type="text" class="form-control" v-model="filteringKeyword">
								</div>
							</div>
						</div>

						<div class="col-lg-5">
							<div :class="{ row: true, 'mobile-hidden': sortingMobileHidden }">
								<h4 class="col-12">Ordenação</h4>

								<div class="form-group col-lg-6 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="sortingField"
										:options="$store.state[module].sorting.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-6 col-12">
									<label>Ordem:</label>
									<multiselect
										v-model="sortingDirection"
										:options="$store.state[module].sorting.directions"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<main class="content">
				<div class="d-flex justify-content-center" v-if="$store.state.people.fetching">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="container-fluid">
						<div class="row">
							<div class="col">
								<table class="desktop-list-content">
									<thead>
									<tr>
										<th class="sortable" @click="setOrder('people.id')">
											Id <ej-order-icon :module="module" :field="'people.id'"></ej-order-icon>
										</th>
										<th class="sortable" @click="setOrder('people.name')">
											Nome Fantasia <ej-order-icon :module="module" :field="'people.name'"></ej-order-icon>
										</th>
										<th class="sortable" @click="setOrder('people.business_name')">
											Razão Social <ej-order-icon :module="module" :field="'people.business_name'"></ej-order-icon>
										</th>
										<th class="sortable" @click="setOrder('city.name')">
											Cidade <ej-order-icon :module="module" :field="'city.name'"></ej-order-icon>
										</th>
										<th class="sortable" @click="setOrder('people.reg_type')">
											Cad. <ej-order-icon :module="module" :field="'people.reg_type'"></ej-order-icon>
										</th>
										<th>
											Telefones
										</th>
										<th></th>
									</tr>
									</thead>
									<tbody>
									<tr :key="index" v-for="(person, index) in $store.state.people.people">
										<td>{{ person.id }}</td>
										<td>{{ person.name }}</td>
										<td>{{ person.business_name }}</td>
										<td>{{ person.reg_type === 'full' ? `${person.city.name}/${person.city.state.acronym}` : '' }}</td>
										<td>{{ translateRegType(person.reg_type) }}</td>
										<td>{{ _.get(person, 'phones') ? person.phones.join(' / ') : '' }}</td>
										<td class="actions">
											<router-link class="btn btn-primary mr-1" :to="{name: 'people.edit', params: {id: person.id}}">
												<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
											</router-link>
											<button class="btn btn-primary" @click="deletePerson(person.id, index)">
												<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
											</button>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="mobile-list-content">
							<div class="row">
								<div class="col-12 col-md-6" :key="index" v-for="(person, index) in $store.state.people.people">
									<div class="card">
										<div class="card-body">
											<h5 class="card-title"><strong>{{ person.id }}</strong> - {{ person.name }}</h5>
											<p class="card-text"><strong>Razão Social:</strong> {{ person.business_name }}</p>
											<p class="card-text"><strong>Tipo:</strong> {{ translateRegType(person.reg_type) }}</p>
											<p class="card-text"><strong>Cidade:</strong> {{ person.reg_type === 'full' ? `${person.city.name}/${person.city.state.acronym}` : '' }}</p>
											<p class="card-text"><strong>Telefones:</strong> {{ _.get(person, 'phones') ? person.phones.join(' / ') : '' }}</p>

											<div class="actions">
												<router-link class="btn btn-primary" :to="{name: 'people.edit', params: {id: person.id}}">
													<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
												</router-link>

												<router-link class="btn btn-primary" :to="{name: 'people.delete', params: {id: person.id}}">
													<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="container-fluid">
						<div class="row">
							<div class="col d-flex justify-content-center">
								<nav class="pagination-nav" aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item" v-if="$store.state.people.pagination.current_page > 1">
											<button class="page-link" @click="setPage($store.state.people.pagination.current_page-1)">Anterior</button>
										</li>
										<li :class="{ 'page-item':true, active: page === $store.state.people.pagination.current_page}" :key="index" v-for="(page, index) in $store.state.people.pagination.last_page">
											<button class="page-link" @click="setPage(page)">{{ page }}</button>
										</li>
										<li class="page-item" v-if="$store.state.people.pagination.current_page < $store.state.people.pagination.last_page">
											<button class="page-link" @click="setPage($store.state.people.pagination.current_page+1)">Próxima</button>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</main>

			<ej-floating-button @click="$router.push({name: 'people.create'})">
				<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
			</ej-floating-button>
		</ej-content>
	</ej-page>
</template>

<script>
//Components
import Multiselect from 'vue-multiselect';

//Mixins
import SortingMixin from "@/mixins/SortingMixin";
import FilteringMixin from "@/mixins/FilteringMixin";
import PaginationMixin from "@/mixins/PaginationMixin";
import DateMixin from "@/mixins/DateMixin";
import FetchingMixin from "@/mixins/FetchingMixin";
import CurrencyMixin from "@/mixins/CurrencyMixin";

//Aux
import {customWatch, exibeErro} from "@/helpers";
import Swal from "sweetalert2";

export default {
	data() {
		return {
			module: 'people'
		}
	},

	mixins: [
		FilteringMixin,
		SortingMixin,
		PaginationMixin,
		DateMixin,
		FetchingMixin,
		CurrencyMixin
	],

	watch: {
		"$store.state.people.sorting.selected": customWatch,
		"$store.state.people.filtering.selected": customWatch,
		"$store.state.people.filtering.keyword": customWatch,
		"$store.state.people.pagination.current_page": customWatch,
	},

	methods: {
		translateRegType(reg_type) {
			switch (reg_type) {
				case 'full':
					return 'Completo';

				case 'simple':
					return 'Simples';
			}
		},

		deletePerson(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja excluir essa pessoa?',
				icon: 'warning',
				confirmButtonText: 'Sim, excluir',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`people/delete`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pessoa deletada com sucesso!',
								'success'
							).then(() => {
								vm.$delete(vm.$store.state.people.people, index);
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		}
	},

	mounted() {
		//Fetch inicial
		this.requestData();
	},

	components: {
		Multiselect,
	},
}
</script>

<style scoped>

</style>
